import { useState } from 'react';
import { useQuery } from 'react-query';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { GetPendingSubAccountInfoFn } from '../../../data/apiCalls';

const PendingSubAccountTable = ({
	dataAPIUrl,
	resendInviteCallback,
	testRowCount,
}) => {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [filterModel, setFilterModel] = useState({ items: [] });
	const [sortModel, setSortModel] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);

	const [resendInviteModalOpen, setResendInviteModalOpen] = useState(false);
	const handleOpen = () => setResendInviteModalOpen(true);
	const handleClose = () => setResendInviteModalOpen(false);
	const [subAccount, setSubAccount] = useState({});

	const handleClick = (event, params) => {
		event.preventDefault();
		const action = params.value;
		if (action === 'resend invite') {
			setSubAccount(params.row);
			handleOpen();
		}
	};

	const handleResend = () => {
		handleClose();
		resendInviteCallback(subAccount);
	};

	const linkText = {
		textTransform: 'uppercase',
		fontWeight: 'bold',
	};

	const columns = [
		{
			field: 'emailAddress',
			headerName: 'Email',
			flex: 3,
			minWidth: 100,
			valueFormatter: params => {
				if (!params.value) return '';
				return params.value;
			},
		},
		{
			field: 'dateSent',
			headerName: 'Date Sent',
			sortable: false,
			flex: 1,
			minWidth: 120,
			valueFormatter: params => {
				if (!params.value) return '';
				return new Date(params.value).toLocaleDateString();
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			renderCell: params => {
				if (params.value === 'expired') {
					return (
						<Chip
							label={params.value}
							variant='outlined'
							icon={<CircleIcon color='error' />}
							sx={{ textTransform: 'capitalize' }}
						/>
					);
				}
				return (
					<Chip
						label={params.value}
						variant='outlined'
						icon={<CircleOutlinedIcon />}
						sx={{ textTransform: 'capitalize' }}
					/>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			flex: 1,
			renderCell: params => {
				if (params.row.status === 'expired') {
					return (
						<Button
							disableRipple
							href='#'
							onClick={e => handleClick(e, params)}
							underline='none'
							sx={{
								...linkText,
								'&:hover': { backgroundColor: 'transparent' },
							}}
						>
							{params.value}
						</Button>
					);
				}
				return '';
			},
		},
	];

	const updatePageSize = newSize => {
		const newModel = paginationModel;
		newModel.pageSize = newSize;
		setPaginationModel(newModel);
		setPageSize(newSize);
	};

	const updatePage = newPage => {
		const newModel = paginationModel;
		newModel.page = newPage;
		setPaginationModel(newModel);
		setPage(newPage);
	};

	const accountData = useQuery(
		['PendingSubAccount', page, paginationModel, sortModel, filterModel],
		() =>
			GetPendingSubAccountInfoFn(
				dataAPIUrl,
				page,
				paginationModel.pageSize,
				sortModel,
				filterModel,
				testRowCount
			),
		{ staleTime: Infinity }
	);

	const { data: { data: { rows = [], rowCount = 0 } = {} } = {} } =
		accountData;
	const { isLoading } = accountData;

	return (
		<>
			<DataGrid
				autoHeight
				pagination
				disableColumnResize
				disableColumnMenu
				disableColumnFilter
				disableColumnSelector
				disableColumnSorting
				disableDensitySelector
				hideFooterSelectedRowCount
				getRowId={row => row.subAccountId}
				rowHeight={58}
				rowsPerPageOptions={[10, 20, 50]}
				columns={columns}
				rows={rows}
				loading={isLoading}
				paginationMode='server'
				filterMode='server'
				onPaginationModelChange={setPaginationModel}
				onSortModelChange={setSortModel}
				onFilterModelChange={setFilterModel}
				pageSize={pageSize}
				onPageSizeChange={newSize => {
					updatePageSize(newSize);
				}}
				onPageChange={newPage => {
					updatePage(newPage);
				}}
				rowCount={rowCount}
				initialState={{
					columns: { columnVisibilityModel: { id: false } },
				}}
				sx={{
					'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
						outline: 'none',
					},
				}}
			/>
			<Dialog
				open={resendInviteModalOpen}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Resend Invite</DialogTitle>
				<DialogContent>
					<Typography id='alert-dialog-description'>
						Are you sure you want to resend an invite to this sub
						account?
					</Typography>
					<Grid container columnSpacing={2} mt={2}>
						<Grid container item spacing={3}>
							<Grid item xs={3}>
								<Typography>
									<b>First Name</b>
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography>
									<b>Last Name</b>
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>
									<b>Email</b>
								</Typography>
							</Grid>
						</Grid>
						<Grid container item spacing={3}>
							<Grid item xs={3} overflow='hidden'>
								<Typography>{subAccount.firstName}</Typography>
							</Grid>
							<Grid item xs={3} overflow='hidden'>
								<Typography>{subAccount.lastName}</Typography>
							</Grid>
							<Grid item xs={6} overflow='hidden'>
								<Typography>
									{subAccount.emailAddress}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ mb: 2 }}>
					<Button
						variant='outlined'
						onClick={handleClose}
						disableRipple
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						onClick={handleResend}
						disableRipple
						autoFocus
					>
						Resend Invite
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

PendingSubAccountTable.propTypes = {
	dataAPIUrl: PropTypes.string.isRequired,
	resendInviteCallback: PropTypes.func.isRequired,
	testRowCount: PropTypes.number,
};

PendingSubAccountTable.defaultProps = {
	testRowCount: 0,
};

export default PendingSubAccountTable;
