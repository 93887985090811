import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MyAccountTablePage from './shared/MyAccountTablePage';

const EditSubAccounts = () => {
	const [asCSelectionModel, setAsCSelectionModel] = useState([]);
	const [alCSelectionModel, setAlCSelectionModel] = useState([]);
	const [
		associatedClinicsRequiredModalOpen,
		setAssociatedClinicsRequiredModalOpen,
	] = useState(false);

	const submit = () => {
		if (asCSelectionModel.length === 0 && alCSelectionModel.length === 0) {
			setAssociatedClinicsRequiredModalOpen(true);
		}
	};

	return (
		<Paper elevation={0}>
			<Container sx={{ py: 2 }}>
				<Typography variant='h5' component='h1' mb={2}>
					Edit Sub Account
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<Typography>Edit the sub account information below</Typography>
				<Typography fontWeight='fontWeightBold' mt={2}>
					Sub Account&apos;s Email Address:
				</Typography>
				<Typography>johns@gmail.com</Typography>

				<MyAccountTablePage
					pageTitle='Clinics Associated With Sub Account'
					dataAPIUrl=''
					testRowCount={3}
					selectAll
					selectionModelUpdate={setAsCSelectionModel}
					queryKey='associateClinics'
				/>

				<MyAccountTablePage
					pageTitle='Select Clinic:'
					dataAPIUrl='api/ashlinknextapigateway/Clinical/PostLogin/MyAccount/Clinics'
					selectionModelUpdate={setAlCSelectionModel}
					queryKey='allClinics'
				/>

				<Box
					sx={{
						mt: 6,
					}}
				>
					<Typography variant='h6'>
						Select the ASHLink Access Level:
					</Typography>
					<RadioGroup defaultValue='3'>
						<FormControlLabel
							value='1'
							control={<Radio />}
							label={
								<div>
									<Typography>
										<strong>Level 1: </strong>
										Users are granted full access to all
										features of ASHLink.
									</Typography>
								</div>
							}
						/>
						<FormControlLabel
							value='2'
							control={<Radio />}
							label={
								<div>
									<Typography>
										<strong>Level 2: </strong>
										Same as Level 1 - with the exception of
										viewing Provider Service Agreements and
										inviting additional sub account users.
									</Typography>
								</div>
							}
						/>
						<FormControlLabel
							value='3'
							control={<Radio />}
							label={
								<div>
									<Typography>
										<strong>Level 3: </strong>
										User access is limited to submitting +
										viewing messages, viewing benefits, and
										submitting MNR forms.
									</Typography>
								</div>
							}
						/>
					</RadioGroup>
				</Box>

				<Box
					sx={{
						mt: 6,
					}}
				>
					<Typography
						sx={{
							fontStyle: 'italic',
						}}
					>
						If you have any questions, please select
						&lsquo;Help&lsquo; located on the blue task bar above
						and &lsquo;Submit an online Question or Comment&lsquo;.
						Responses can be viewed in your &lsquo;Inbox&lsquo;.
					</Typography>
				</Box>

				<Stack
					direction='row'
					sx={{
						justifyContent: 'space-between',
					}}
				>
					<Box>
						<Button
							variant='contained'
							sx={{
								mr: 2,
							}}
							onClick={submit}
						>
							Confirm Sub Account
						</Button>
						<Button variant='outlined'>Cancel</Button>
					</Box>
					<Button variant='contained' color='error'>
						Terminate This Account
					</Button>
				</Stack>
			</Container>
			<Dialog open={associatedClinicsRequiredModalOpen}>
				<DialogContent>
					<DialogContentText>
						At least 1 associated clinic is required.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() =>
							setAssociatedClinicsRequiredModalOpen(false)
						}
						autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default EditSubAccounts;
