import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { React, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { GetClinicInfoFn } from '../../../data/apiCalls';
import CustomTextDataGridOverlay from '../../shared/CustomTextDataGridOverlay/CustomTextDataGridOverlay';
import { formatPhone } from '../../../scripts/phoneNumberHelper';

const StyledAddressBlock = styled('div')`
	overflow: hidden;

	.address-line {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

const MyAccountTablePage = ({
	pageTitle,
	dataAPIUrl,
	selectAll,
	error,
	testRowCount,
	selectionModelUpdate,
	queryKey,
}) => {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [filterModel, setFilterModel] = useState({ items: [] });
	const [sortModel, setSortModel] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [selectionModel, setSelectionModel] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);

	const columns = [
		{
			field: 'id',
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Id
				</Typography>
			),
		},
		{
			field: 'name',
			flex: 1,
			minWidth: 300,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Clinics
				</Typography>
			),
		},
		{
			field: 'address',
			flex: 1,
			minWidth: 300,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Address
				</Typography>
			),
			renderCell: params => (
				<StyledAddressBlock>
					<div className='address-line'>{params.row.address1}</div>
					<div className='address-line'>{params.row.address2}</div>
					<div className='address-line'>
						{params.row.city}, {params.row.state}{' '}
						{params.row.zipCode}
					</div>
				</StyledAddressBlock>
			),
		},
		{
			field: 'info',
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Info
				</Typography>
			),
			width: 200,
			renderCell: params => (
				<span>
					<div>
						<strong>Phone: </strong>
						{formatPhone(params.row.phone)}
					</div>
					<div>
						<strong>Fax: </strong>
						{formatPhone(params.row.fax)}
					</div>
					<div>
						<strong>Tax ID #: </strong>
						{params.row.taxId}
					</div>
				</span>
			),
		},
		{
			field: 'effective',
			width: 120,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Effective
				</Typography>
			),
			valueFormatter: params => {
				if (!params.value) return '';
				return dayjs(params.value).format('MM/DD/YYYY');
			},
		},
		{
			field: 'termed',
			width: 120,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Termed
				</Typography>
			),
			valueFormatter: params => {
				if (!params.value) return '';
				return dayjs(params.value).format('MM/DD/YYYY');
			},
		},
	];

	const updatePageSize = newSize => {
		const newModel = paginationModel;
		newModel.pageSize = newSize;
		setPaginationModel(newModel);
		setPageSize(newSize);
	};

	const updatePage = newPage => {
		const newModel = paginationModel;
		newModel.page = newPage;
		setPaginationModel(newModel);
		setPage(newPage);
	};

	const clinicData = useQuery(
		[queryKey, page, paginationModel, sortModel, filterModel],
		() =>
			GetClinicInfoFn(
				dataAPIUrl,
				page,
				paginationModel.pageSize,
				sortModel,
				filterModel,
				testRowCount,
				selectAll
			),
		{ staleTime: Infinity }
	);

	const {
		data: {
			data: { rows = [], rowCount = 0, selectedRows = [] } = {},
		} = {},
	} = clinicData;

	useEffect(() => {
		// Automatically set selected rows on inital backend call
		if (selectedRows.length > 0 && firstLoad) {
			setSelectionModel(selectedRows);
			setFirstLoad(false);
		}
	}, [selectedRows]);

	useEffect(() => {
		selectionModelUpdate(selectionModel);
	}, [selectionModel]);

	return (
		<Box
			sx={{
				mt: 6,
			}}
		>
			<Typography variant='h6' component='label' sx={{ mb: 1 }}>
				{pageTitle}
			</Typography>
			<DataGrid
				autoHeight
				pagination
				disableColumnResize
				disableColumnMenu
				disableColumnFilter
				disableColumnSelector
				disableColumnSorting
				disableDensitySelector
				checkboxSelection
				keepNonExistentRowsSelected
				rowHeight={100}
				rowsPerPageOptions={[10, 20, 50]}
				columns={columns}
				rows={rows}
				paginationMode='server'
				filterMode='server'
				onPaginationModelChange={setPaginationModel}
				onSortModelChange={setSortModel}
				onFilterModelChange={setFilterModel}
				pageSize={pageSize}
				loading={clinicData.isLoading}
				onPageSizeChange={newSize => {
					updatePageSize(newSize);
				}}
				onPageChange={newPage => {
					updatePage(newPage);
				}}
				rowCount={rowCount}
				initialState={{
					columns: { columnVisibilityModel: { id: false } },
				}}
				selectionModel={selectionModel}
				onSelectionModelChange={newSelection => {
					setSelectionModel(newSelection);
				}}
				components={{
					Toolbar: GridToolbar,
				}}
				componentsProps={{
					toolbar: {
						showQuickFilter: rows && rows.length > 0,
						printOptions: {
							disableToolbarButton: true,
						},
						csvOptions: { disableToolbarButton: true },
						quickFilterProps: { debounceMs: 0 },
					},
				}}
				slots={{
					noRowsOverlay: CustomTextDataGridOverlay,
				}}
				slotProps={{
					loadingOverlay: {
						variant: 'skeleton',
						noRowsVariant: 'skeleton',
						'data-testid': 'datagridLoadingOverlay',
					},
				}}
				sx={{
					'.MuiDataGrid-columnHeaderTitleContainer': {
						'.MuiSvgIcon-root': {
							color: 'white',
						},
					},
				}}
			/>
			{error && <FormHelperText error>{error}</FormHelperText>}
		</Box>
	);
};

MyAccountTablePage.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	dataAPIUrl: PropTypes.string.isRequired,
	queryKey: PropTypes.string.isRequired,
	selectAll: PropTypes.bool,
	error: PropTypes.string,
	testRowCount: PropTypes.number,
	selectionModelUpdate: PropTypes.func,
};

MyAccountTablePage.defaultProps = {
	selectAll: false,
	testRowCount: 0,
	error: undefined,
	selectionModelUpdate: () => function a() {},
};

export default MyAccountTablePage;
