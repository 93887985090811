import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import Footer from './components/shared/Footer/Footer';
// import ContactUs from './components/shared/ContactUs';
// import PrivacyStatement from './components/shared/PrivacyStatement';
// import TermsAndConditions from './components/shared/TermsAndConditions';
import Resources from './components/Resources/Resources';
import ClientSummaries from './components/Resources/ClientSummaries';
import ScrollToTop from './components/shared/ScrollToTop';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Header from './components/shared/Header/Header';
import Notifications from './components/Resources/Notifications';
import ProviderServicesAgreements from './components/Resources/ProviderServicesAgreements';
import ValueAddedProgram from './components/Resources/ValueAddedProgram';
import OperationsManual from './components/Resources/OperationsManual';
import Help from './components/Help/Help';
import FAQ from './components/Help/FAQ';
import Newsletters from './components/Resources/Newsletters';
import ProtectedRoute from './components/shared/ProtectedRoute/ProtectedRoute';
import {
	CLINICAL_PAGE_ROUTES,
	PAGE_PATHS,
	TOP_LEVEL_ROUTES,
} from './scripts/routes';
import useSessionContext from './data/queryHooks/useSessionContext';
import CustomLoading from './components/shared/CustomLoading';
import ProviderEducationLibrary from './components/Resources/ProviderEducationLibrary';
import Compliance from './components/Compliance/Compliance';
import { HOME_URL, LOGOUT_URL } from './scripts/navigationItems';
import PractitionerEducationLibrary from './components/Resources/PractitionerEducationLibrary';
import PractitionerServicesAgreements from './components/Resources/PractitionerServicesAgreements';
import IdleModal from './components/shared/Modal/IdleModal';
import { idleTracker } from './scripts/idleTracker';
import { GetSessionContext } from './data/apiCalls';
import Forms from './components/Resources/Forms';
import PELVideo from './components/Resources/PELVideo';
import AuthSubmission from './components/Forms/Auth/Submit/AuthSubmission';
import ProviderPractitionerVirtualServicesAgreement from './components/Resources/ProviderPractitionerVirtualServicesAgreement';
import ManageSubAccounts from './components/MyAccount/ManageSubAccounts';
import EditSubAccounts from './components/MyAccount/EditSubAccounts';
import MyAccount from './components/MyAccount/MyAccount';

const RootLayout = styled('div')`
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		'header'
		'main'
		'footer';
	min-height: 100vh;

	.header {
		grid-area: header;
	}

	.main {
		grid-area: main;
	}

	.footer {
		grid-area: footer;
	}
`;

function App() {
	const { isLoading, isLoggedIn } = useSessionContext();
	const location = useLocation();
	const [modalOpen, setModalOpen] = useState(false);
	const [staySingedIn, setStaySignedIn] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const checkSession = async () => {
		try {
			const res = await GetSessionContext();
			if (res?.resultCode !== 0)
				window.open(LOGOUT_URL, '_self', 'noreferrer');
		} catch (error) {
			return error;
		}
		return null;
	};
	useEffect(() => {
		checkSession();
	}, [location.pathname]);
	useEffect(() => {
		if (staySingedIn) {
			setModalOpen(false);
			idleTracker(isLoggedIn, openModal);
			setStaySignedIn(false);
		}
	}, [staySingedIn]);

	useEffect(() => {
		idleTracker(isLoggedIn, openModal);
	}, [isLoggedIn]);

	if (isLoading) {
		return (
			<Container sx={{ mt: 7 }}>
				<CustomLoading />
			</Container>
		);
	}

	if (!isLoggedIn) {
		window.open(HOME_URL, '_self', 'noreferrer');
		return (
			<Container sx={{ mt: 7 }}>
				<CustomLoading />
			</Container>
		);
	}

	return (
		<RootLayout>
			<Header classname='header' />
			<IdleModal
				open={modalOpen}
				setSignedIn={() => setStaySignedIn(true)}
			/>
			<Box classname='main' component='main' sx={{ mb: 5 }}>
				<ScrollToTop />
				<Routes>
					<Route element={<ProtectedRoute />}>
						<Route path='/public'>
							{/* <Route path="contactus" element={<ContactUs />} />
					<Route path="privacystatement" element={<PrivacyStatement />} />
					<Route path="termsandconditions" element={<TermsAndConditions />} /> */}
							<Route
								path={CLINICAL_PAGE_ROUTES.pageNotFound}
								element={<PageNotFound />}
							/>
							<Route
								path=''
								element={
									<Navigate to={PAGE_PATHS.pageNotFound} />
								}
							/>
						</Route>
						<Route
							path={TOP_LEVEL_ROUTES.resources}
							element={<Resources />}
						>
							<Route
								path={CLINICAL_PAGE_ROUTES.clientSummaries}
								element={<ClientSummaries />}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.notifications}
								element={<Notifications />}
							/>
							<Route
								path={
									CLINICAL_PAGE_ROUTES.providerServiceAgreements
								}
								element={<ProviderServicesAgreements />}
							/>
							<Route
								path={
									CLINICAL_PAGE_ROUTES.practitionerServiceAgreements
								}
								element={<PractitionerServicesAgreements />}
							/>
							<Route
								path={
									CLINICAL_PAGE_ROUTES.providerPractitionerVirtualServicesAgreement
								}
								element={
									<ProviderPractitionerVirtualServicesAgreement />
								}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.valueAddedProgram}
								element={<ValueAddedProgram />}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.operationsManual}
								element={<OperationsManual />}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.forms}
								element={<Forms />}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.newsletters}
								element={<Newsletters />}
							/>
							<Route
								path={
									CLINICAL_PAGE_ROUTES.practitionerEducationLibrary
								}
								element={<PractitionerEducationLibrary />}
							/>
							<Route
								path={
									CLINICAL_PAGE_ROUTES.providerEducationLibrary
								}
								element={<ProviderEducationLibrary />}
							/>
							<Route
								path={`${CLINICAL_PAGE_ROUTES.practitionerEducationLibrary}/:video`}
								element={<PELVideo />}
							/>
						</Route>
						<Route
							path={TOP_LEVEL_ROUTES.compliance}
							element={<Compliance />}
						/>
						<Route path={TOP_LEVEL_ROUTES.help} element={<Help />}>
							<Route
								path={CLINICAL_PAGE_ROUTES.faq}
								element={<FAQ />}
							/>
						</Route>
						<Route
							path={TOP_LEVEL_ROUTES.account}
							element={<MyAccount />}
						>
							<Route
								path={CLINICAL_PAGE_ROUTES.manageSubAccounts}
								element={<ManageSubAccounts />}
							/>
							<Route
								path={CLINICAL_PAGE_ROUTES.editSubAccounts}
								element={<EditSubAccounts />}
							/>
						</Route>
						<Route path='forms'>
							<Route path='auth'>
								<Route
									path='submit'
									element={<AuthSubmission />}
								/>
							</Route>
						</Route>
						<Route
							path=''
							element={<Navigate to={PAGE_PATHS.pageNotFound} />}
						/>
						<Route
							path='*'
							element={<Navigate to={PAGE_PATHS.pageNotFound} />}
						/>
					</Route>
				</Routes>
			</Box>
			<Footer classname='footer' />
		</RootLayout>
	);
}

export default App;
