import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';
import ASHLogo from '../../../assets/American_Specialty_Health_Logo_Dark.svg';
import FooterMenu from './FooterMenu';
import useIsMobile from '../../../hooks/useIsMobile';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import { getFooterNavs } from '../../../scripts/navigationHelpers';

const copyright = `© ${new Date().getFullYear()} American Specialty Health Incorporated (ASH). All rights reserved.`;

const Footer = props => {
	const isMobile = useIsMobile();
	const { permissions, redirectionLinks } = useSessionContext();

	const navigationItems = getFooterNavs(permissions, redirectionLinks);

	return (
		<Box
			{...props}
			component='footer'
			sx={{
				backgroundColor: 'background.default',
				color: 'text.primary',
				p: 0,
			}}
		>
			<Box>
				<Container>
					<Grid
						container
						mb={{ xs: 4, md: 6 }}
						display='flex'
						justifyContent='space-between'
					>
						<Grid item xs={12} sm={12} md={4} lg={3.5}>
							<Stack>
								<Typography
									mt={{ xs: 2, md: 7 }}
									mb={1}
									variant='overline'
									component='p'
								>
									A Product of
								</Typography>
								<Box
									sx={{ width: '15rem', height: '2rem' }}
									mb={2}
								>
									<img src={ASHLogo} alt='ASH Logo' />
								</Box>
							</Stack>
						</Grid>
						{!isMobile && (
							<Grid item md={6} display='flex' flexWrap='wrap'>
								<FooterMenu menuItems={navigationItems} />
							</Grid>
						)}
					</Grid>
					<Divider
						sx={{
							borderColor: 'grey',
							margin: { xs: 0, sm: '2.5rem 0' },
						}}
						orientation='horizontal'
					/>
					{isMobile && (
						<Grid display='flex' flexWrap='wrap' mb={6}>
							<FooterMenu menuItems={navigationItems} />
						</Grid>
					)}
					<Typography variant='body2' component='p' sx={{ mb: 2 }}>
						<OpenInNewIcon
							sx={{
								fontSize: 16,
								top: '0.1rem',
								position: 'relative',
							}}
						/>{' '}
						Clicking on these links will take you away from
						ASHLink.com. ASHLink is not responsible for any content
						or the privacy practices of external websites.
					</Typography>
					<Typography variant='body2' component='p' sx={{ mb: 5 }}>
						ASHLink is a product of American Specialty Health.
						ASHLink complies with applicable Federal civil right
						laws and does not discriminate on the basis of race,
						color, national origin, age, disability, sex.
					</Typography>
				</Container>
			</Box>
			<Box sx={{ backgroundColor: 'primary.dark' }} py={4} px={2}>
				<Container>
					<Stack
						spacing={2}
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent='center'
					>
						<Typography
							variant='body2'
							color='primary.contrastText'
							textAlign={{ xs: 'center' }}
						>
							{copyright}
						</Typography>
					</Stack>
				</Container>
			</Box>
		</Box>
	);
};

export default Footer;
