import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

/**
 * The CustomTextDataGridOverlay component is designed to be used in the slots prop of a MUI DataGird component.
 * It renders the text centered in the DataGrid.
 * For example:
 * ```
 * 	<DataGrid
 * 		columns={columns}
 * 		rows={rows}
 * 		slots={{
 * 			noRowsOverlay: CustomTextDataGridOverlay,
 * 		}}
 * 		slotProps={{
 * 			noRowsOverlay: {
 * 				text: isError
 * 					? "We're sorry, something went wrong. We're unable to load the results at this time. Please refresh and try again."
 * 					: 'There are no results for this search.',
 * 			},
 * 		}}
 * 	/>
 * ```
 */
const CustomTextDataGridOverlay = ({ text }) => (
	<Stack
		alignItems='center'
		justifyContent='center'
		textAlign='center'
		sx={{ height: '100%', mx: 1 }}
	>
		<Typography>{text}</Typography>
	</Stack>
);

CustomTextDataGridOverlay.propTypes = {
	text: PropTypes.string.isRequired,
};

export default CustomTextDataGridOverlay;
