import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import CustomAlert from '../shared/CustomAlert';
import PageLayout from '../shared/PageLayout/PageLayout';
import PendingSubAccountTable from './shared/PendingSubAccountTable';
import SubAccountTable from './shared/SubAccountTable';

const SlideLeft = props => <Slide {...props} direction='left' />;

const ManageSubAccounts = () => {
	const [showTermAccountAlert, setShowTermAccountAlert] = useState(false);
	const [alertText, setAlertText] = useState({ title: '', description: '' });
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	// TODO: when integrating, edit callback should redirect to the edit sub account page
	// this example shows the use case when an account is terminated from the edit page
	const editSubAccount = subAccount => {
		const email = subAccount.emailAddress;
		const text = {
			title: 'Sub Account Successfully Terminated',
			description: `${email} has been terminated.`,
		};
		setAlertText(text);
		setShowTermAccountAlert(true);
	};

	// TODO: when integrating, resendInvite callback should make the api call to resend invite
	// this example shows the use case when resend invite call is successful
	const resendInvite = subAccount => {
		setShowTermAccountAlert(false);
		const email = subAccount.emailAddress;
		const text = {
			title: 'Sub Account Creation Invite Sent',
			description: `An email has been sent to ${email} for them to create their account. The link will expire after 72 hours.`,
		};
		setAlertText(text);
		setOpenSnackbar(true);
	};

	return (
		<PageLayout pageTitle='Manage Sub Accounts'>
			<Paper elevation={0} sx={{ py: 2, px: { md: 4, lg: 4 } }}>
				<Typography variant='h5' component='h5' my={1}>
					Sub Accounts
				</Typography>
				<Divider />
				<Typography variant='body1' my={2}>
					Add new and/or manage all of your sub accounts.
				</Typography>
				{showTermAccountAlert && (
					<CustomAlert
						headerText={alertText.title}
						bodyText={alertText.description}
					/>
				)}
				<Alert severity='info' sx={{ mb: 3 }}>
					<Typography fontWeight={700}>
						What is a Sub Account?
					</Typography>
					<Typography variant='body1'>
						Sub Accounts provide a user access to an individual
						provider account and multiple clinic locations under the
						same provider. This access is typically intended for
						practitioners and other office staff. Depending on the
						level of access, the designated users can submit eMNRs,
						eClaims, or access Remittance Advice.
					</Typography>
				</Alert>
				<SubAccountTable
					pageTitle='Sub Accounts'
					dataAPIUrl='api/ashlinknextapigateway/Clinical/PostLogin/MyAccount/SubAccounts'
					editSubAccountCallback={editSubAccount}
					testRowCount={11}
				/>
			</Paper>
			<Paper elevation={0} sx={{ mt: 4, py: 2, px: { md: 4, lg: 4 } }}>
				<Typography variant='h5' component='h5' my={1}>
					Pending Sub Accounts
				</Typography>
				<Divider sx={{ mb: 4 }} />
				<PendingSubAccountTable
					pageTitle='Pending Sub Accounts'
					dataAPIUrl='api/ashlinknextapigateway/Clinical/PostLogin/MyAccount/PendingSubAccounts'
					resendInviteCallback={resendInvite}
					testRowCount={9}
				/>
			</Paper>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				TransitionComponent={SlideLeft}
			>
				<Alert severity='success'>
					<Typography fontWeight={700}>{alertText.title}</Typography>
					<Typography>{alertText.description}</Typography>
				</Alert>
			</Snackbar>
		</PageLayout>
	);
};
export default ManageSubAccounts;
