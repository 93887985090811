import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const CustomAlert = ({ headerText, bodyText }) => (
	<Alert severity='success' sx={{ mb: 3 }}>
		<Typography fontWeight={700}>{headerText}</Typography>
		<Typography>{bodyText}</Typography>
	</Alert>
);

export default CustomAlert;

CustomAlert.propTypes = {
	headerText: PropTypes.string.isRequired,
	bodyText: PropTypes.string.isRequired,
};
