import { useState } from 'react';
import { useQuery } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
	DataGrid,
	GridFooter,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { GetSubAccountInfoFn } from '../../../data/apiCalls';
import CustomLoading from '../../shared/CustomLoading';

const linkText = {
	textTransform: 'uppercase',
	fontWeight: 'bold',
};

const CreateSubAccountLink = ({ clickHandler }) => (
	<Button
		disableRipple
		href='#'
		underline='none'
		onClick={clickHandler}
		sx={{ '&:hover': { backgroundColor: 'transparent' } }}
	>
		<Stack direction='row'>
			<AddIcon />
			<Typography sx={{ ...linkText, ml: 1 }}>
				create new sub account
			</Typography>
		</Stack>
	</Button>
);

const CustomToolbar = ({ clickHandler }) => (
	<GridToolbarContainer sx={{ justifyContent: 'space-between', mb: 0.5 }}>
		<CreateSubAccountLink clickHandler={clickHandler} />
		<GridToolbarQuickFilter placeholder='Search...' />
	</GridToolbarContainer>
);

const SubAccountTable = ({
	dataAPIUrl,
	editSubAccountCallback,
	testRowCount,
}) => {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [filterModel, setFilterModel] = useState({ items: [] });
	const [sortModel, setSortModel] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);

	const handleClick = (event, params) => {
		event.preventDefault();
		const action = params?.value;
		if (action === 'edit') {
			editSubAccountCallback(params.row);
		}
	};

	const statusMapping = {
		active: {
			icon: <CircleIcon color='success' />,
		},
		termed: {
			icon: <CircleIcon color='error' />,
		},
	};

	const columns = [
		{
			field: 'firstName',
			headerName: 'First Name',
			flex: 1,
			minWidth: 100,
			valueFormatter: params => {
				if (!params.value) return '';
				return params.value;
			},
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			flex: 1,
			minWidth: 100,
			valueFormatter: params => {
				if (!params.value) return '';
				return params.value;
			},
		},
		{
			field: 'emailAddress',
			headerName: 'Email',
			flex: 3,
			minWidth: 100,
			valueFormatter: params => {
				if (!params.value) return '';
				return params.value;
			},
		},
		{
			field: 'level',
			headerName: 'Level',
			flex: 1,
			minWidth: 100,
			valueFormatter: params => {
				if (!params.value) return '';
				return params.value;
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			flex: 2,
			renderCell: params => {
				const status = params.value.startsWith('termed')
					? 'termed'
					: params.value;
				const { color, icon } = statusMapping[status];
				return (
					<Chip
						variant='outlined'
						label={params.value}
						color={color}
						icon={icon}
						sx={{ textTransform: 'capitalize' }}
					/>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			flex: 1,
			renderCell: params => (
				<Button
					disableRipple
					href='#'
					onClick={e => handleClick(e, params)}
					sx={{
						...linkText,
						'&:hover': { backgroundColor: 'transparent' },
					}}
				>
					{params.value}
				</Button>
			),
		},
	];

	const updatePageSize = newSize => {
		const newModel = paginationModel;
		newModel.pageSize = newSize;
		setPaginationModel(newModel);
		setPageSize(newSize);
	};

	const updatePage = newPage => {
		const newModel = paginationModel;
		newModel.page = newPage;
		setPaginationModel(newModel);
		setPage(newPage);
	};

	const accountData = useQuery(
		['SubAccount', page, paginationModel, sortModel, filterModel],
		() =>
			GetSubAccountInfoFn(
				dataAPIUrl,
				page,
				paginationModel.pageSize,
				sortModel,
				filterModel,
				testRowCount
			),
		{ staleTime: Infinity }
	);

	const { data: { data: { rows = [], rowCount = 0 } = {} } = {} } =
		accountData;
	const { isLoading } = accountData;

	return (
		<DataGrid
			autoHeight
			pagination
			disableColumnResize
			disableColumnMenu
			disableColumnFilter
			disableColumnSelector
			disableColumnSorting
			disableDensitySelector
			disableMultipleSelection
			hideFooterSelectedRowCount
			getRowId={row => row.subAccountId}
			rowHeight={58}
			rowsPerPageOptions={[10, 20, 50]}
			columns={columns}
			rows={rows}
			loading={isLoading}
			paginationMode='server'
			filterMode='server'
			onPaginationModelChange={setPaginationModel}
			onSortModelChange={setSortModel}
			onFilterModelChange={setFilterModel}
			pageSize={pageSize}
			onPageSizeChange={newSize => {
				updatePageSize(newSize);
			}}
			onPageChange={newPage => {
				updatePage(newPage);
			}}
			rowCount={rowCount}
			initialState={{
				columns: { columnVisibilityModel: { id: false } },
			}}
			components={{
				LoadingOverlay: CustomLoading,
				Footer: GridFooter,
				Toolbar: CustomToolbar,
			}}
			componentsProps={{
				toolbar: {
					showQuickFilter: rows.length > 0,
					quickFilterProps: { debounceMs: 1000 },
				},
			}}
			sx={{
				'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
					outline: 'none',
				},
			}}
		/>
	);
};

CustomToolbar.propTypes = {
	clickHandler: PropTypes.func,
};
CustomToolbar.defaultProps = () => {};

CreateSubAccountLink.propTypes = {
	clickHandler: PropTypes.func,
};
CreateSubAccountLink.defaultProps = () => {};

SubAccountTable.propTypes = {
	dataAPIUrl: PropTypes.string.isRequired,
	editSubAccountCallback: PropTypes.func.isRequired,
	testRowCount: PropTypes.number,
};

SubAccountTable.defaultProps = {
	testRowCount: 0,
};

export default SubAccountTable;
