import { rest } from 'msw';

const chooseRandom = options =>
	options[Math.floor(Math.random() * options.length)];

const randomDate = (start, end) =>
	new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);

const randomNumber = (min, max) => {
	const minCeiled = Math.ceil(min);
	const maxFloored = Math.floor(max);
	return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

const randomSpecialtyCodes = [27, 28, 36, 42];

const randomClinics = [
	'Angelwing Hospital',
	'Bayhealth General Hospital',
	'Blossom Hospital',
	'Charity Hospital',
	'Charity Medical Clinic',
	'Citrus Medical Center',
	'Clearview Clinic',
	'Clearwater Valley Community Hospital',
	'Evergreen General Hospital',
	'Forest Health Hospital Center',
	'Fortuna Clinic',
	'Fountain Valley Community Hospital',
	'Garden City Hospital Center',
	'Goldriver Hospital Center',
	'Grand Meadow Medical Center',
	'Grand Mountain General Hospital',
	'Grand University Community Hospital',
	'Greengrass General Hospital',
	'Hillsdale Medical Clinic',
	'Honor Community Hospital',
	'Hopedale Community Hospital',
	'Lakeland General Hospital',
	'Lilypad Clinic',
	'Lilypad Gardens Community Hospital',
	'Little River Community Hospital',
	'Maple Grove Medical Clinic',
	'Memorial Clinic',
	'Mercy Medical Clinic',
	'Mercy Vale Hospital',
	'North Star Hospital Center',
	'Oakdale Hospital Center',
	'Pinevalley Medical Center',
	'Pioneer Community Hospital',
	'Progress Medical Clinic',
	'Riverview Hospital',
	'Rose Valley Clinic',
	'Ruby Valley Community Hospital',
	'Silver Birch General Hospital',
	'Silver Birch Medical Center',
	'Silver Hill Medical Center',
	'Specialty General Hospital',
	'Spring Harbor Community Hospital',
	'Summerstone Medical Center',
	'Summit Clinic',
	'Tranquility Hospital Center',
	'United Hospital',
	'Wellness Medical Clinic',
	'Wildflower Medical Center',
	'Willow Gardens Hospital Center',
	'Willow Gardens Hospital',
];

const randomCities = [
	'Elkton',
	'Mahwah',
	'Hudson',
	'Harrison Township',
	'Belmont',
	'Yonkers',
	'Waukegan',
	'Hackettstown',
	'Bridgeton',
	'Greenwood',
	'Branford',
	'Patchogue',
	'Fair Lawn',
	'Bay City',
	'Egg Harbor Township',
	'Davison',
	'Dalton',
	'Decatur',
	'Elizabethtown',
	'Woonsocket',
	'Chapel Hill',
	'Palm Bay',
	'Burke',
	'Camden',
	'Chambersburg',
	'Danbury',
	'Baton Rouge',
	'Stroudsburg',
	'Ballston Spa',
	'Beltsville',
	'Warner Robins',
	'Fairborn',
	'Worcester',
	'Carol Stream',
	'West Chicago',
	'Farmingdale',
	'Davison',
	'Nottingham',
	'Corpus Christi',
	'Central Islip',
	'Sarasota',
	'Memphis',
	'Opa Locka',
	'Miami Gardens',
	'Teaneck',
	'Selden',
	'Lincoln Park',
	'Perth Amboy',
	'Chippewa Falls',
	'Hartford',
];

const randomAddresses1 = [
	'12 West Columbia Rd.',
	'129 W. Leatherwood Street',
	'17 Pennington Ave.',
	'24 Santa Clara Rd.',
	'28 Elm Street',
	'30 Swanson Lane',
	'31 Illinois Drive',
	'338 Central Dr.',
	'34 West Valley St.',
	'353 Kingston Ave.',
	'37 Peachtree St.',
	'377 Southampton St.',
	'4 East Lafayette Dr.',
	'408 Smoky Hollow Rd.',
	'491 Cross Court',
	'5 South Devon Lane',
	'51 Kingston St.',
	'51 Sierra Dr.',
	'514 Evergreen Avenue',
	'55 Green Lake Lane',
	'556 Dunbar Ave.',
	'585 Glenholme Lane',
	'60 Princeton Ave.',
	'628 Clay Lane',
	'644 Sierra Ave.',
	'68 West Stonybrook Ave.',
	'7 New Avenue',
	'7020 Pennington Ave.',
	'766 Thorne Ave.',
	'7787 Glenlake St.',
	'7788 Squaw Creek Dr.',
	'7824 Pleasant St.',
	'790 Rockaway Street',
	'7967 N. Cleveland Lane',
	'804 East Warren Dr.',
	'8455 Mammoth St.',
	'8523 Meadow Street',
	'8579 Clinton Street',
	'8623 Southampton Dr.',
	'8900 Annadale St.',
	'911 Jockey Hollow Drive',
	'9229 Arch Ave.',
	'9296 Devon Ave.',
	'9366 Cross St.',
	'9391 Ketch Harbour Ave.',
	'9398 Harvard Road',
	'9430 Garfield Lane',
	'95 Ramblewood St.',
	'9668 Morris Street',
	'9688 Boston St.',
];

const randomAddresses2 = () => [
	`STE ${randomNumber(1, 200)}`,
	`#${randomNumber(1, 200)}`,
	`Apt ${randomNumber(1, 200)}`,
];

const randomZipCodes = [
	'21921',
	'07430',
	'03051',
	'48045',
	'02478',
	'10701',
	'60085',
	'07840',
	'08302',
	'29646',
	'06405',
	'11772',
	'07410',
	'48706',
	'08234',
	'48423',
	'30721',
	'30030',
	'17022',
	'02895',
	'27516',
	'32907',
	'22015',
	'08105',
	'17201',
	'06810',
	'70806',
	'18360',
	'12020',
	'20705',
	'31088',
	'45324',
	'01604',
	'60188',
	'60185',
	'11735',
	'48423',
	'21236',
	'78418',
	'11722',
	'34231',
	'38106',
	'33054',
	'33056',
	'07666',
	'11784',
	'48146',
	'08861',
	'54729',
	'06106',
];

const randomFirstNames = [
	'Alexis',
	'Annie',
	'Ari',
	'Audrey',
	'Cedric',
	'Corey',
	'Daphne',
	'Dayton',
	'Demarcus',
	'Dwayne',
	'Eileen',
	'Fabian',
	'Finley',
	'Gavin',
	'Gilberto',
	'Holden',
	'Houston',
	'Jace',
	'Jacey',
	'Jaime',
	'Jamari',
	'Jarrett',
	'Jasmin',
	'Jayson',
	'Jeramiah',
	'Jocelynn',
	'Justin',
	'Karter',
	'Katelynn',
	'Kathy',
	'Katie',
	'Kelsey',
	'Kenya',
	'Luna',
	'Madeleine',
	'Mikayla',
	'Monserrat',
	'Peter',
	'Pierre',
	'Rihanna',
	'Rylee',
	'Saniya',
	'Sebastian',
	'Tabitha',
	'Tamara',
	'Tatiana',
	'Terry',
	'Todd',
	'Yazmin',
	'Yuliana',
];

const states = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY',
];

const randomLastNames = [
	'Andrade',
	'Ayers',
	'Beard',
	'Best',
	'Blair',
	'Cisneros',
	'Contreras',
	'Cooley',
	'Copeland',
	'Cordova',
	'Daugherty',
	'Dixon',
	'Downs',
	'Elliott',
	'Farmer',
	'Finley',
	'Gentry',
	'Guerrero',
	'Henderson',
	'House',
	'Johns',
	'Kane',
	'Krause',
	'Lowe',
	'Maxwell',
	'Mccullough',
	'Mcintosh',
	'Mckenzie',
	'Mckinney',
	'Mora',
	'Moses',
	'Moss',
	'Mullins',
	'Nguyen',
	'Ochoa',
	'Pope',
	'Powell',
	'Pruitt',
	'Reyes',
	'Richardson',
	'Roman',
	'Ruiz',
	'Shepherd',
	'Smith',
	'Valencia',
	'Velazquez',
	'Welch',
	'Wheeler',
	'Whitney',
	'Wilkins',
];

export const GenerateRandomPracitioners = numberOfPractitioners =>
	[...new Array(numberOfPractitioners)].map(() => {
		const specialtyCode = chooseRandom(randomSpecialtyCodes);
		return {
			practitionerId: Math.floor(Math.random() * 1000000),
			lastName: chooseRandom(randomLastNames),
			firstName: `${chooseRandom(randomFirstNames)} (${specialtyCode})`,
			specialtyCode,
			npiTypeI: Math.floor(Math.random() * 1000000),
			drEffectiveDate: '',
			drTermDate:
				randomNumber(0, 10) === 1
					? randomDate(
							new Date('2020-01-01'),
							new Date('2026-01-01')
					  ).toLocaleDateString()
					: null,
			plSeq: 1,
			stateCode: 'tx',
			plEffectiveDate: '',
			plTermDate: '',
		};
	});

export const GetPractitionersSuccess = practitioners => [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						practitioners,
					},
					date: new Date().toISOString(),
				})
			)
	),
];

export const GetSinglePractitionerSuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						practitioners: [
							{
								practitionerId: 123456789,
								lastName: 'Smith',
								firstName: `Jane`,
								specialtyCode: 1,
								npiTypeI: 10001000,
								drEffectiveDate: '',
								drTermDate: null,
								plSeq: 1,
								stateCode: 'tx',
								plEffectiveDate: '',
								plTermDate: '',
							},
						],
					},
					date: new Date().toISOString(),
				})
			)
	),
];

export const GetTwoPractitionersSuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						practitioners: [
							{
								practitionerId: 123456789,
								lastName: 'Smith',
								firstName: `Jane`,
								specialtyCode: 27,
								npiTypeI: 10001000,
								drEffectiveDate: '',
								drTermDate: null,
								plSeq: 1,
								stateCode: 'tx',
								plEffectiveDate: '',
								plTermDate: '',
							},
							{
								practitionerId: 1234512345,
								lastName: 'Doe',
								firstName: `John`,
								specialtyCode: 28,
								npiTypeI: 99919991,
								drEffectiveDate: '',
								drTermDate: null,
								plSeq: 1,
								stateCode: 'tx',
								plEffectiveDate: '',
								plTermDate: '',
							},
						],
					},
					date: new Date().toISOString(),
				})
			)
	),
];

export const GetPractitionersLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const GetPractitionersError = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) => res(ctx.status(500))
	),
];

export const GetPractitionersGatewayError = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/Practitioners',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 1,
					resultText: 'Fail',
					subResultCode: 32,
					subResultText: 'AshlinkClinicalApiFail',
					errors: [
						{
							message:
								'An Error has occured in the AshlinkClinicalApi, Transaction Id: 9a1d24e2-a3a4-4e0f-99c2-459e53ba587c',
						},
					],
					date: '2023-02-22T05:16:50.4312862Z',
				})
			)
	),
];

export const GetServiceLocationsSuccessRandom = numberOfLocations => [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/ServiceLocations',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						serviceLocations: [...new Array(numberOfLocations)].map(
							() => ({
								id: randomNumber(0, 1000000),
								name: chooseRandom(randomClinics),
								providerDocSuffix: 'AA',
								practitionerDocSuffix: '00',
								taxId: 'taxid1234',
								phone: '5555555555',
								address1: chooseRandom(randomAddresses1),
								address2:
									randomNumber(1, 5) === 4
										? chooseRandom(randomAddresses2())
										: '',
								city: chooseRandom(randomCities),
								state: chooseRandom(states),
								zipCode: chooseRandom(randomZipCodes),
								effectiveDate: randomDate(
									new Date('01-01-2020'),
									new Date('01-01-2024')
								).toLocaleDateString(),
								termDate: randomDate(
									new Date('01-02-2024'),
									new Date('01-01-2028')
								).toLocaleDateString(),
							})
						),
					},
					date: new Date().toISOString(),
				})
			)
	),
];

export const QAGetServiceLocationsSuccessRandom = numberOfLocations => [
	rest.get('/api/Promis/ClinicInfo', (req, res, ctx) =>
		res(
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					serviceLocations: [...new Array(numberOfLocations)].map(
						() => ({
							id: 'qatest',
							clinicName: 'qatest',
							clinicLevel: 1,
							address1: '111 test address',
							address2: '111 test address',
							city: 'test city',
							state: 'test state',
							zip: '1111',
							effectiveDate: randomDate(
								new Date('01-01-2020'),
								new Date('01-01-2024')
							).toLocaleDateString(),
							termDate: randomDate(
								new Date('01-02-2024'),
								new Date('01-01-2028')
							).toLocaleDateString(),
						})
					),
				},
				date: new Date().toISOString(),
			})
		)
	),
];

export const GetServiceLocationsLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/ServiceLocations',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const GetServiceLocationsError = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Provider/ServiceLocations',
		(req, res, ctx) => res(ctx.status(500))
	),
];
